import styled from "styled-components";

export const PreviewWrapper = styled.div`
  background-color: var(--bs-white);
  p,
  .footer a,
  #navbarScroll a {
  }
  h2 {
  }
  h3,
  h4 {
  }
  h5,
  h6 {
  }
  a {
    text-decoration: none;
  }

  &.no-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--bs-gray-light);
  }

  .empty {
    color: var(--bs-gray-light);
    /* font-style: italic; */
  }

  h5 {
    font-family: 'Gilroy-Bold';
  }
  // section ONE
  .certifed-badge {
    text-align: right;
    max-height: 5.25rem;
  }
  .company-logo {
    width: 5.125rem;
    aspect-ratio: 1;
    margin-right: 16px;
    display: flex;
    object-fit: cover;
    align-items: center;
    justify-content: center;
  }
  p.company-details-heading {
    padding-bottom: 0.5rem;
  }
  .company-logo img {
    width: 100%;
  }
  .socialicon ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
  }
  .socialicon li {
    display: inline-block;
    vertical-align: middle;
  }
  .socialicon i {
    color: var(--bs-black);
    margin: auto;
  }
  .socialicon a {
    width: 2.188rem;
    font-size: 1.18rem;
    height: 2.188rem;
    border: 1px solid var(--bs-black);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    text-decoration: none;
  }

  .socialicon a:hover {
    border: 1px solid var(--bs-red);
    background-color: var(--bs-red);
  }
  .socialicon a:hover i {
    color: var(--bs-white);
  }

  .why-great-content {
    font-size: 1rem;
  }

  .why-great-content :is(p, ul, ol, a, div) {
    font-size: 1rem;
  }

  .why-great-content :is(b) {
    font-size: 1rem;
    font-family: 'Gilroy-SemiBold';
  }

  // SECTION TWO
  .card-wrapper {
  }
  .main-badge {
    padding-top: 1rem;
  }
  .card-badge:first-child {
    object-fit: contain;
  }
  .card-badge {
    width: 100%;
    height: 7.5rem;
    object-fit: cover;
  }

  .card-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-family: 'Gilroy-SemiBold';
  }

  .card-body {
    padding: 1rem;
  }
`;
