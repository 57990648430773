import { Button } from "components/Button/styled";

const { useEffect } = require("react");
const { useNavigate } = require("react-router-dom");

export default function NotFound({ isLoggedIn }) {
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) return;
    navigate("/register/logincustomer");
  }, [navigate, isLoggedIn]);
  if (isLoggedIn) {
    return (
      <div className="position-absolute top-50 start-50 translate-middle d-flex flex-column gap-3 align-items-center">
        <h1>404</h1>
        <p>Page not found!</p>
        <Button
          className="solid"
          type="submit"
          onClick={(e) => navigate("/dashboard/getdashboard")}
        >
          Go to Dashboard
        </Button>
      </div>
    );
  }
  return null;
}
