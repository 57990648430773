import styled from "styled-components";

export const TitleWrapper = styled.div`
  gap: 0.8rem;
  .tooltip-wrapper {
    position: absolute;
    top: -364%;
    left: -230%;
  }
`;
