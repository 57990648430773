import { HybreedIcon, LogoImage } from "utils/imageConstants";
import React from "react";
import { NavbarRight, NavbarWrapper } from "./styled";
import Button, { ImageButtonComponent as ImageButton } from "components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLogoutThunk } from "./../../redux/thunk/userLoginThunk";

function NavbarComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {company_logo, company_name} = useSelector((state) => state.auth.userData) || '';

  const handleLogout = async () => {
    try {
      const action = await dispatch(userLogoutThunk());
      navigate('/');
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <NavbarWrapper className="sticky-top">
      <nav className="navbar container">
        <img
          className="img-fluid nav-logo"
          src={LogoImage}
          alt=""
        />
        <NavbarRight>
          <ImageButton label={company_name} classImage={'company-logo object-fit-cover'} className="img-fluid" alt={company_name} src={company_logo}  />
          <Button className="bordered" label="Logout" onClick={() => handleLogout()} />
        </NavbarRight>
      </nav>
    </NavbarWrapper>
  );
}

export default NavbarComponent;

