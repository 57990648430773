import styled from "styled-components";

export const NavbarWrapper = styled.div`
    background-color: var(--bs-white);
    padding: 1rem 0;
    border-bottom: 1px solid var(--bs-light);

    ${props => props.bottom && `
        padding: 1.5rem 0;
        box-shadow: 0px -2px 35px 0px rgba(0, 0, 0, 0.08);
    `}

    
    .nav-logo {
        width: 3.5rem;
        aspect-ratio: 1;
    }
`

export const NavbarRight = styled.div`
    display: inline-flex;
    gap: 2rem;
    
    .company-logo {
        border-radius: 0.30681rem;
    }

`