import styled from "styled-components";

export const Button = styled.button`
    border: 0;
    border-radius: 0;
    box-sizing: content-box;
    color: var(--bs-white);
    cursor: pointer;
    display: inline;
    font: inherit;
    font-family: inherit;
    font-size: 1rem;
    font-style: inherit;
    font-weight: inherit;
    line-height: normal;
    margin: 0;
    overflow: visible;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    touch-action: manipulation;
    transition: none 0s ease 0s;
    user-select: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    will-change: auto;


    &:active {
        transform: scale(0.98);
        box-shadow: none;
        transition: scale 0.2s ease-in-out;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    &.solid {
        background-color: red;
        font-family: Gilroy-SemiBold;

        &:hover {
            background-color: var(--bs-black);
            transition: background-color 0.2s ease-in-out;
        }
    }
    &.solid-blue {
        font-family: Gilroy-SemiBold;
        background-color: var(--bs-blue);
        &:hover {
            background-color: var(--bs-blue-dark);
            transition: background-color 0.2s ease-in-out;
        }
    }
    &.bordered {
        border: 1px solid var(--bs-red);
        color: var(--bs-red);
        background-color: transparent;

        &:hover {
            color: var(--bs-white);
            background-color: var(--bs-red);
            transition: background-color 0.2s ease-in-out;
        }
    }
    // IMAGE BUTTON
    &.img-btn {
        display: inline-flex;
        gap: 0.75rem;
        padding: 0;
        align-items: center;
        background-color: transparent;
        .img {
            width: 1.6875rem;
            aspect-ratio: 1;
        }
        .label {
            font-family: Gilroy-SemiBold;
            color: #1F1F1F;
        }
    }

    .link-logo {
        /* min-width: 1rem; */
        height: 1rem;
        /* object-fit: contain; */
        aspect-ratio: 1;
    }
`