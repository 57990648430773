import React, { useEffect, useRef, useState } from 'react'
import { SliderWrapper } from './styled'
import { ImageBrokenIcon } from 'utils/imageConstants';
function SliderComponent({ sliderContent, type }) {
  const carouselRef = useRef(null);
  const renderSlider = () => {
    if (!sliderContent) return null;
    if (type === "text") return Object.keys(sliderContent).map((item, index) => (
      <div className="banner-slider" key={index}>
        <p className='comment'>{sliderContent[item]}</p>
      </div>
    ));

    if (type === "image") return Object.keys(sliderContent).map((item, index) => {
        return (
          <div className={sliderContent[item] ? "banner-slider" : "d-none"} key={index}>
            {sliderContent[item] && <img alt="banner" src={sliderContent[item]} className="carousel-image"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = ImageBrokenIcon;
                currentTarget.className = 'broken-image';
              }}
            />}
          </div>
        )
    })
  }
  const sliderContentRendered = renderSlider();

  useEffect(() => {
    const flikty = new window.Flickity(carouselRef.current, {
      // Flickity options
      freeScroll: false,
      wrapAround: type === "text" ? false : true,
      pageDots: type === "text" ? true : false,
      autoPlay: type === "text" ? true : false,
      resize: false,
      contain: true,
      fullscreen: true,
      lazyLoad: 0,
      imagesLoaded: true,
      cellSelector: '.banner-slider',

    });
      return () => flikty.destroy();
  }, [carouselRef, sliderContentRendered]);

  return (
    <SliderWrapper className="carousel slide pointer-event" data-bs-ride="carousel" data-flickity>
      <div className="carousel flickity-enabled" ref={carouselRef} defaultValue={sliderContent}>
        {sliderContentRendered}
      </div>
    </SliderWrapper>
  )
}
export default SliderComponent
