import styled from "styled-components";

export const Input = styled.div`

  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  &.textarea {
    gap: 0.8rem;
  }
  input[type],
  textarea {
    appearance: none;
    border: 0;
    resize: vertical;
    outline: 0;
    background: none;
    margin: 0;
    padding: 1rem;
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    border: 1px solid #11131b;

    &::placeholder {
      opacity: 0.9;
    }
  }

  .disabled {
    cursor: not-allowed;
  }

  input.error-input {
      border: 1px solid var(--bs-red);
  }

  label.label {
    display: flex;
    font-size: 1rem;
    gap: 0.1rem;
  }

  .error {
    color: var(--bs-red);
    font-size: 0.85rem;
  }

  .input-length {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const SocialButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  input.input {
    appearance: none;
    border: 0;
    outline: 0;
    background: none;
    margin: 0;
    padding: 1rem;
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    border: 1px solid #11131b;
    border-left: none;

    &::placeholder {
      opacity: 0.9;
    }
  }

  label.label {
    padding: 0.2rem 1rem;
    display: inline-flex;
    align-items: center;
    border: 1px solid #11131b;
    img {
      width: 1.5rem;
      aspect-ratio: 1;
    }
  }

  .error {
    color: var(--bs-red);
    font-size: 0.85rem;
  }
`;
