import styled from "styled-components";

export const ModalWrapper = styled.div`
  height: 100vh;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 33px 54px 0px rgba(99, 99, 99, 0.05);

  /* width: 100%; */

  .modal-body {
    animation: fadeIn 0.1s ease-in-out;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    padding: 3rem 6rem;
    @media (max-width: 768px) {
      max-width: 90%;
    }
    @media (min-width: 769px) and (max-width: 1024px) {
      max-width: 60%;
    }
    @media (min-width: 1025px) {
      max-width: 40%;
    }
    flex-direction: column;
    gap: 3rem;
    background-color: var(--bs-white);
    display: flex;
    justify-content: center;
    align-items: center;

    .body-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      gap: 0.5rem;
    }

    .modal-icon {
      max-width: 9rem;
      aspect-ratio: 1;
    }

    .closeBtn {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;

      img {
        border-radius: 50%;
        transition: box-shadow 0.3s ease-in-out;
      }
      &:hover img {
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      }
    }
  }
`;
