import styled from "styled-components";

export const SliderWrapper = styled.div`
  text-align: center;
  .flickity-viewport {
    /* max-height: 20rem; */
    .flickity-slider {
      /* overflow: visible; */
      /* transform: translateX(-504.65%); */

      .banner-slider[aria-hidden="true"] {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }
      .comment {
        padding: 0 2.75rem;
        font-size: 1.5rem;
        font-style: italic;
        font-family: "Gilroy-Bold";
      }
    }
  }

  .carousel img {
    /* display: block;
    object-fit: contain;
    height: 100%;
    min-width: 100%; */
  }

  .carousel {
    .carousel-image {
      min-width: 100%;
      height: 20rem;
    }
  }

  .flickity-page-dots {
    margin-bottom: 4.5rem;
    bottom: -6.25rem;
    position: relative;
    .dot {
      width: 1.063rem;
      height: 1.063rem;
    }

    .dot.is-selected {
      background-color: #ff1c1c;
    }
  }
`;
