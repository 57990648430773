import React from "react";
import { PreviewWrapper } from "./styled";
import SliderComponent from "../SliderComponent";
import { ImageBrokenIcon } from "utils/imageConstants";

function PreviewComponent({ data }) {
  const {
    company_name = "",
    company_logo = "",
    badge_logo = "",
    why_great = "",
    company_size = "",
    industry = "",
    social_links = {},
    city = "",
    recognitions = [],
    slider = [],
    employee_quote = {},
  } = data || {};

  const socialIcon = (tag) => {
    switch (tag) {
      case "fb":
        return (
          <li>
            <a href={social_links[tag]} target="_blank">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
        );
      case "twitter":
        return (
          <li>
            <a href={social_links[tag]} target="_blank">
              <i class="fa-brands fa-x-twitter"></i>
            </a>
          </li>
        );
      case "linkedin":
        return (
          <li>
            <a href={social_links[tag]} target="_blank">
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
          </li>
        );
      case "instagram":
        return (
          <li>
            <a href={social_links[tag]} target="_blank">
              <i class="fa-brands fa-instagram"></i>
            </a>
          </li>
        );
      case "youtube":
        return (
          <li>
            <a href={social_links[tag]} target="_blank">
              <i class="fa-brands fa-youtube"></i>
            </a>
          </li>
        );
      // case "whatsapp":
      //   return (
      //     <li><a href={socialLinksDummy[tag]} target="_blank">
      //       <i class="fa-brands fa-whatsapp"></i></a>
      //     </li>)
      default:
      case "website":
        return (
          <li>
            <a href={social_links[tag]} target="_blank">
              <i className="fa fa-globe"></i>
            </a>
          </li>
        );
    }
  };

  const isEmpty = Object.keys(data).length === 0;

  const allSliderStringsEmpty = slider.every((str) => str.trim() === "");
  const allEmployeeQuoteStringsEmpty = Object.values(employee_quote).every((str) => str.trim() === "");

  return (
    <PreviewWrapper>
      <section className={isEmpty ? "container" : "container"}>
        <div className="row">
          <div className="col-lg-12 mx-auto">
            <div className="row align-items-center">
              <div className="col-lg-9">
                <div className="d-flex align-items-center pt-4 pb-4">
                  {<div className="company-logo">
                    <img src={company_logo} 
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = ImageBrokenIcon;
                      }}
                     alt="company-logo" />
                  </div>}
                  <h5 className={`${company_name.length ? "" : "empty"}`}>
                    {company_name || "Company Name"}
                  </h5>
                </div>
              </div>
              <div className="col-lg-3 text-end">
                <img
                  className="certifed-badge"
                  src={badge_logo}
                  alt="badge"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = ImageBrokenIcon;
                    currentTarget.className = 'broken-image';
                    currentTarget.title = 'broken-image';
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <h5 className="pb-4">
                  Why{" "}
                  <span className={`${company_name.length ? "" : "empty"}`}>
                    {company_name || "Company Name"}
                  </span>{" "}
                  is a Great Place To Work
                </h5>
                {why_great ? (
                  <div
                    className="why-great-content"
                    dangerouslySetInnerHTML={{
                      __html: why_great || "Why Great content here...",
                    }}
                  ></div>
                ) : (
                  <p className="empty">Why Great content here...</p>
                )}
              </div>
            </div>
            <div className="companny-details row pt-4 flex-shrink-auto">
              <div className="col-lg-3">
                <p className="bold company-details-heading">COMPANY SIZE</p>
                <p>
                  <span className={`${company_size ? "" : "empty"}`}>
                    {company_size || "-"}
                  </span>{" "}
                  based Employees
                </p>
              </div>
              <div className="col-lg-3 ">
                <p className="bold company-details-heading">INDUSTRY</p>
                <p className={`${industry.length ? "" : "empty"}`}>
                  {industry || "Industry Name"}
                </p>
              </div>
              {!!Object.keys(social_links).length && (
                <div className="col-lg-3 socialicon">
                  <p className="bold company-details-heading">CONNECT</p>
                  <ul className="icons">
                    {Object.keys(social_links).map((link, index) => {
                      if (social_links[link].length) {
                        return socialIcon(link);
                      }
                    })}
                  </ul>
                </div>
              )}
              <div className="col-lg-auto">
                <p className="bold company-details-heading">HQ LOCATION</p>
                <p className={`${city.length ? "" : "empty"}`}>
                  {city || "City Name"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={isEmpty ? "pt-5 pb-5" : "pt-5 pb-5"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h5 className="sectionHeading pb-4">
                Recognitions awarded by Great Place To Work®
              </h5>
              <div className="row">
                <div className="col-lg-4 mb-4">
                  <img
                    className="img-fluid main-badge card-badge row"
                    src="https://www.greatplacetowork.in/great/api/assets/uploads/5257/logo/batch.png"
                    alt="India’s Best Workplaces™ in FMCG 2024."
                  />
                  <div className="card-body">
                    <p className="card-title">Great Place to Work-Certified™</p>
                  </div>
                </div>
                {recognitions.map((item, index) => (
                  <div className="col-lg-4 mb-4 card-wrapper">
                    <img
                      className="img-fluid card-badge row"
                      src={item.img}
                      alt="India’s Best Workplaces™ in FMCG 2024."
                    />
                    <div className="card-body">
                      <p className="card-title">{item.title}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Section Three */}
      {!allSliderStringsEmpty && (
        <section className={"pb-5"}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h5 className="text-center pb-4">
                  Life at{" "}
                  <span className={`${company_name.length ? "" : "empty"}`}>
                    {company_name || "Company Name"}
                  </span>
                </h5>
                <SliderComponent sliderContent={slider} type={"image"} />
              </div>
            </div>
          </div>
        </section>
      )}
      {/* Section Four */}
      {!allEmployeeQuoteStringsEmpty && (
        <section className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h5 className="text-center pb-4">Employee Testimonials</h5>
                <SliderComponent sliderContent={employee_quote} type={"text"} />
              </div>
            </div>
          </div>
        </section>
      )}
    </PreviewWrapper>
  );
}

export default PreviewComponent;
