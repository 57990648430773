import { BASE_URL } from "api/constants";
import { getToken } from "utils/helper";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  postCompanyDataFailure,
  postCompanyDataSuccess,
} from "./../../redux/slice/userSlice";

export const postDataThunk = createAsyncThunk(
  "postCompanyData",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const token = getToken();
      const response = await axios.post(
        `${BASE_URL}/dashboard/updateDashboard`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data;",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        dispatch(postCompanyDataSuccess(response.data)); // Update state with actual data
        return response.data;
      } else {
        const errorMessage = response.data?.message || 'Unknown error'; // Extract specific error message
        dispatch(postCompanyDataFailure(response.data)); // Update state with actual data
        return rejectWithValue(errorMessage)
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);
