import React from 'react'
import { NavbarRight, NavbarWrapper } from './styled'
import { Button } from 'components/Button/styled'

function BottomNavbar({className, onSubmit, children}) {
  return (
    <NavbarWrapper bottom className={`${className} sticky-bottom`}>
      <nav className="container navbar justify-content-end">
        <NavbarRight>
            {children}
        </NavbarRight>
      </nav>
    </NavbarWrapper>
  )
}

export default BottomNavbar