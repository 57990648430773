import React from "react";
import { LoginSection, LoginWrapper } from "./styled";
import { LogoImage } from "utils/imageConstants";
import FormComponent from "components/Form";
import Input from "components/Input";
import { useForm } from "react-hook-form";
import { EMAIL_REGEX } from "utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import { userLoginThunk, userNewLoginThunk } from "./../../redux/thunk/userLoginThunk";

export default function LoginScreen() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading)
  const loginError = useSelector((state) => state.auth.error);
  const isNewUser = useSelector((state) => state.auth.isNewUser);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const actionPromise = isNewUser ? dispatch(userNewLoginThunk(data)) : dispatch(userLoginThunk(data));
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Login request timed out')), 10000);
    });
    
    dispatch(userLoginThunk(data)).catch((error) => {
      console.error(JSON.stringify(error), 'TIMEOUT ERROR');
      throw error;
    });
    try {
      const action = await Promise.race([actionPromise, timeoutPromise]);
      // navigate('/dashboard/getdashboard'); // Navigate to /dashboard/getdashboard on success
    } catch (error) {
      console.error(JSON.stringify(error), 'LOGIN ERROR');
      throw error;
    }
  };

  const handleErrors = (errors) => {
  };
  return (
    <LoginWrapper className="container">
      <div className="row align-items-center h-100 w-100">
        {/* LEFT SECTION */}
        <LoginSection className="col-lg-6 d-none d-lg-flex" id="left">
          <img className="img-fluid login-banner" src={'https://www.greatplacetowork.in/wp-content/uploads/2024/02/Website-images-with-Grid-1.jpg'} alt="login-banner" />
        </LoginSection>
        {/* RIGHT SECTION */}
        <LoginSection className="col-lg-6 d-flex flex-column h-100" id="right">
          {/* FORM HEADER */}
          <div className="form-header d-flex flex-column h-25">
            <div className="d-flex justify-content-center">
              <img src={LogoImage} alt="" className="img-fluid logo" />
            </div>
            <h5 className="text-center">Welcome to Great Place to work</h5>
          </div>
          {/* FORM */}
          <FormComponent
            width={"29.5rem"}
            footerContent={
              "If you are Great Place to Work India's Customer, kindly contact your RM for your login credentials to edit your Company's profile page"
            }
          >
            <div className="input-container d-flex flex-column">
            {isNewUser &&<h6 className="text-center">Enter New Password</h6>}
              <Input
                type="text"
                name="email"
                label="Email"
                placeholder={"Enter your email"}
                errors={errors}
                register={register}
                pattern={EMAIL_REGEX}
                validationSchema={{
                  required: "Email is required",
                  pattern: {
                    value: EMAIL_REGEX,
                    message: "Please enter a valid email",
                  },
                  minLength: {
                    value: 3,
                    message: "Please enter a minimum of 3 characters",
                  },
                }}
                required
              />
              <Input
                type="password"
                name="password"
                label={isNewUser ? "New Password" : "Password"}
                errors={errors}
                placeholder={"Enter your password"}
                register={register}
                // pattern={PASSWORD_REGEX}
                validationSchema={{
                  required: "Password is required",
                  minLength: {
                    value: 3,
                    message: "Please enter a minimum of 3 characters",
                  },
                }}
                error={Boolean(loginError)}
                helperText={loginError}
                required
              />
            </div>
            {loginError && <p className="error pt-2">{loginError}</p>}
            <Button isLoading={isLoading} className="solid" type="submit" onClick={handleSubmit(onSubmit, handleErrors)} label={'SIGN IN'} />
          </FormComponent>
          {/* FOOTER */}
          <div>
            <p className="copyright text-center pb-3">
              Copyright © 2024 Great Place To Work® India.
              <br />
              All rights reserved.
            </p>
          </div>
        </LoginSection>
      </div>
    </LoginWrapper>
  );
}
