import { createSlice } from "@reduxjs/toolkit";
import { userLoginThunk } from "../thunk/userLoginThunk";
import { fetchCompanyDataThunk } from "../thunk/getDataThunk";
import { getToken } from "utils/helper";
import { postDataThunk } from "../thunk/postDataThunk";

const initialState = {
  isLoggedIn: false,
  isNewUser: null,
  token:getToken(),
  user: null,
  userData: null,
  isLoading: false,
  success: null,
  error: null,
};

export const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess(state, action) {
      state.isLoggedIn = action.payload.login;
      state.isNewUser = action.payload.data.is_first_login;
      state.token = action.payload.data.jwt;
      state.success = action.payload.message;
      state.user = action.payload.data;
      state.isLoading = false;
      state.error = null;
    },
    loginFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.message;
    },
    logout(state) {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
    },

    fetchCompanyDataStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    fetchCompanyDataSuccess(state, action) {
      state.success = action.payload.message;
      state.isLoading = false;
      state.error = null;
      state.userData = action.payload;
    },
    fetchCompanyDataFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.message;
    },
    postCompanyDataSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.message = action.payload.message;
    },
    postCompanyDataFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload.error;
      state.message = action.payload.message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(userLoginThunk.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action.payload;
        state.isLoading = false;
        state.error = null;
        state.token = getToken();
      })
      .addCase(userLoginThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(fetchCompanyDataThunk.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchCompanyDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
      })
      .addCase(postDataThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postDataThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.message = action.payload.message;
      })
      .addCase(postDataThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.error;
        state.message = action.payload.message;
      });
  },
});

export const { loginStart, loginSuccess, fetchCompanyDataSuccess, postCompanyDataSuccess, postCompanyDataFailure, loginFailure, logout } =
  userSlice.actions;
export default userSlice.reducer;
