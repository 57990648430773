import { FontVariable, GlobalVariables } from "styles/styles/globalStyled";
import LoginScreen from "screens/LoginScreen";
import Navbar from "components/Navbar";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Dashboard from "screens/Dashboard";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import NotFound from "screens/PageNotFound";
function App() {
  const { token, isLoggedIn } = useSelector((state) => state.auth);

  return (
    <div className="App">
      <GlobalVariables />
      <FontVariable />
      <BrowserRouter>
        {token && <Navbar />}
        <Routes>
          {!token ? (
            <>
              <Route path="/register/logincustomer" element={<LoginScreen />} />
              <Route path="/" element={<LoginScreen />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard/getdashboard" element={<Dashboard />} />
            </>
          )}
          <Route path="*" element={<NotFound isLoggedIn={token} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
