import React from "react";
import { TooltipWrapper } from "./styled";
import { ToolTipDown } from "utils/imageConstants";

export default function ToolTipComponent({ children }) {
  return (
    <TooltipWrapper>
      <div className="tooltip-content">{children}</div>
      <img src={ToolTipDown} alt="tooltip-down" className="tooltip-down" />
    </TooltipWrapper>
  );
}
