import React from 'react'
import { Button } from './styled'
import Spinner from 'components/Spinner';
import { ImageBrokenIcon } from 'utils/imageConstants';

export default function ButtonComponent({
  label,
  className = '',
  onClick,
  isLoading,
  isDisabled = false,
  ...rest
}) {
  return (
    <Button disabled={isDisabled || isLoading} className={`${className} ${isLoading ? 'loading' : ''}`} onClick={onClick} {...rest}>
      {isLoading ? <Spinner /> : label}
    </Button>
  )
}

export function ImageButtonComponent({src, alt, className, classImage, label, onClick}) {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  }
  return (
    <Button type='button' onClick={() => handleOnClick()} className={`img-btn ${className}`}>
      <img className={`img ${classImage}`} src={src} alt={alt}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = ImageBrokenIcon;
        }}
      />
      {label && <span className="label d-none d-sm-block">{label}</span>}
    </Button>
  )
}