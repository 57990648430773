import styled from "styled-components";

export const Input = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  gap: 0.2rem;
  /* overflow: hidden; */
  label.label {
    display: flex;
    font-size: 1rem;
  }

  .error {
    color: var(--bs-red);
    font-size: 0.85rem;
  }
  .dropzone {
    cursor: pointer;
    display: flex;
    justify-content: center;
    border: 0.729px dashed var(--bs-gray);
    position: relative;
    max-width: 16.52294rem;
    height: 11.26563rem;
    object-fit: cover;
    width: 100%;

    .preview {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .no_file {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.64rem;
      padding: 1.2rem;
      height: 100%;
      opacity: 0.5;

      .input-text {
        color: var(--bs-black);
        text-align: center;
        font-family: Gilroy-SemiBold;
        font-size: 0.8rem;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.00156rem;
      }

      .parameters {
        font-family: Gilroy-Regular;
      }
    }

    .delBtn {
      position: absolute;
      right: -0.7rem;
      top: -1rem;
      transition: filter 0.2s, transform 0.2s;
      &:hover {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
        transform: scale(1.2);
      }
    }
  }
`;
