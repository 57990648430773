import React from "react";
import { Input, SocialButton } from "./styled";
import {
  FacebookIcon,
  GlobeIcon,
  InstagramIcon,
  LinkedInIcon,
  TwitterIcon,
  WhatsappIcon,
  YoutubeIcon,
} from "utils/imageConstants";

const socialMedia = ({
  name,
  label,
  placeholder,
  register,
  errors,
  required,
  type,
  img,
  validationSchema,
}) => {

  const fieldName = name.split("[")[0];
  const fieldIndex = name.split("[")[1].split("]")[0];

  return (
    <SocialButton errors={errors} className="">
      <div className="form-control-input d-flex flex-row">
        <label className="label" htmlFor={name}>
          <img src={img} alt="" />
        </label>
        <input
          className="input"
          id={name}
          name={name}
          type={'link'}
          placeholder={placeholder || "Enter here"}
          {...register(name, validationSchema)}
        />
      </div>
      {errors && errors[name]?.type === "required" && (
        <span className="error">{errors[name]?.message}</span>
      )}
      {errors && errors[name]?.type === "minLength" && (
        <span className="error">{errors[name]?.message}</span>
      )}
      {errors[fieldName] && (
        <span className="error">{errors[fieldName][fieldIndex]?.message}</span>
      )}
    </SocialButton>
  );
};

export default function InputComponent({
  name,
  label,
  placeholder,
  register, // register your input with the hook
  errors, // errors will return from the hook
  required,
  type,
  disabled,
  validationSchema,
}) {
  let img = GlobeIcon;
  switch (type) {
    case "facebook":
      img = FacebookIcon;
      return socialMedia({
        name,
        label,
        placeholder,
        register,
        errors,
        required,
        type,
        img,
        validationSchema,
      });
    case "twitter":
      img = TwitterIcon;
      return socialMedia({
        name,
        label,
        placeholder,
        register,
        errors,
        required,
        type,
        img,
        validationSchema,
      });
    case "linkedin":
      img = LinkedInIcon;
      return socialMedia({
        name,
        label,
        placeholder,
        register,
        errors,
        required,
        type,
        img,
        validationSchema,
      });
    case "instagram":
      img = InstagramIcon;
      return socialMedia({
        name,
        label,
        placeholder,
        register,
        errors,
        required,
        type,
        img,
        validationSchema,
      });
    case "youtube":
      img = YoutubeIcon;
      return socialMedia({
        name,
        label,
        placeholder,
        register,
        errors,
        required,
        type,
        img,
        validationSchema,
      });
    case "whatsapp":
      img = WhatsappIcon;
      return socialMedia({
        name,
        label,
        placeholder,
        register,
        errors,
        required,
        type,
        img,
        validationSchema,
      });
    case "custom":
      img = GlobeIcon;
      return socialMedia({
        name,
        label,
        placeholder,
        register,
        errors,
        required,
        type,
        img,
        validationSchema,
      });
    default:
      return (
        <Input
          errors={errors}
          className="form-control-input d-flex flex-column"
        >
          <label className="label" htmlFor={name}>
            <span>{(required || validationSchema?.required) && "*"}</span>
            <span>{label}</span>
          </label>
          <input
            className={`input ${errors[name] ? "error-input" : ""} ${disabled ? "disabled" : ""}`}
            id={name}
            readOnly={disabled}
            name={name}
            type={type}
            placeholder={placeholder || "Enter here"}
            {...register(name, {...validationSchema})}
          />
          {errors && errors[name]?.type === "required" && (
            <span className="error">{errors[name]?.message}</span>
          )}
          {errors && errors[name]?.type === "minLength" && (
            <span className="error">{errors[name]?.message}</span>
          )}
        </Input>
      );
  }
}
