import { loginFailure, loginSuccess, logout } from "./../slice/userSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DUMMY_TOKEN, LOGIN_RESPONSE } from "utils/dummy";
import { BASE_URL } from "api/constants";
import axios from "axios";

export const userLoginThunk = createAsyncThunk(
  "auth",
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      // const response = LOGIN_RESPONSE; // ! DUMMY DATA BYPASSING
      const response = await axios.post(`${BASE_URL}/register/loginCustomer`, credentials, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46YWRtaW4=",
          "Access-Control-Allow-Origin": "*",
          Accept: "*/*",
        },
      });

      if (response.data.success) {
        const data = response.data;
        if (data?.data?.jwt) {
          sessionStorage.setItem("token", data?.data?.jwt);
        }
        // sessionStorage.setItem("token", DUMMY_TOKEN); // ! DUMMY DATA BYPASSING
        dispatch(loginSuccess(data));
      } else {
        dispatch(loginFailure(response.data));
        throw new Error(response.data.message , "LOGIN ERROR");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const userNewLoginThunk = createAsyncThunk(
  "auth",
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      // const response = LOGIN_RESPONSE; // ! DUMMY DATA BYPASSING
      const response = await axios.post(`${BASE_URL}/register/updatePassword`, credentials, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46YWRtaW4=",
          "Access-Control-Allow-Origin": "*",
          Accept: "*/*",
        },
      });

      if (response.data.success) {
        const data = response.data;
        if (data?.data?.jwt) {
          sessionStorage.setItem("token", data?.data?.jwt);
        }
        // sessionStorage.setItem("token", DUMMY_TOKEN); // ! DUMMY DATA BYPASSING
        dispatch(loginSuccess(data));
      } else {
        dispatch(loginFailure(response.data));
        throw new Error(response.data.message , "LOGIN ERROR");
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const userLogoutThunk = () => async (dispatch, getState) => {
  sessionStorage.removeItem("token");
  dispatch(logout());
};
