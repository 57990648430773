import styled from "styled-components";

export const LoginWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    min-height: 100%;
    max-width: 1400px;

    .form-header {
        gap: 1.3rem;
        justify-content: end;
        .logo {
            width: 4.75rem;
            aspect-ratio: 1;
        }
    }
`

export const LoginSection = styled.section`
    display: flex;
    justify-content: center;
    gap: 1.88rem;
    justify-content: space-around;
    align-items: center;

    .login-banner {
        mix-blend-mode: multiply;
        max-height: 90vh;
    }
`