import { createAsyncThunk } from "@reduxjs/toolkit";
import { getToken } from "utils/helper";
import { BASE_URL } from "api/constants";
import axios from "axios";
import { fetchCompanyDataSuccess } from "../../redux/slice/userSlice";
import { DATA_RESPONSE } from "utils/dummy";

export const fetchCompanyDataThunk = createAsyncThunk(
  "getCompanyData",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const token = getToken();
      // const response = DATA_RESPONSE // ! DUMMY DATA BYPASSING
      const response = await axios.get(`${BASE_URL}/dashboard/getDashboard`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "*",
          Accept: "*/*",
        },
      });
      if (!response.data.success) {
        dispatch(fetchCompanyDataSuccess(null));
        return rejectWithValue(response.statusText);
      } else {
        const data = await response.data;
        dispatch(fetchCompanyDataSuccess(data));
        return data.data;
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue(error.message);
    }
  }
);
