import React, { useEffect } from "react";
import { Input } from "components/Input/styled";

function TextArea({
  name,
  label,
  placeholder,
  register, // register your input with the hook
  errors, // errors will return from the hook
  disabled,
  readOnly,
  defaultValue,
  required,
  type,
  validationSchema,
}) {
  const [textLength, setTextLength] = React.useState(0);

  useEffect(() => {
    setTextLength(defaultValue?.length || 0);
  }, [])
  const handleOnChange = (e) => {
    register(e.target.name, validationSchema);
    setTextLength(e.target.value.length);
  }

  return (
    <Input errors={errors} className="textarea form-control-input d-flex flex-column">
      <h6 className="label" htmlFor={name}>
        <span>{label}</span>
      </h6>
      <textarea
        className="input"
        id={name}
        rows={5}
        name={name}
        type={type}
        placeholder={placeholder || "Enter here"}
        value={register.value}
        readOnly={readOnly}
        maxLength={validationSchema?.maxLength?.value}
        {...register(name, validationSchema)}
        onChange={handleOnChange}
      />
      <span className="input-length">{textLength}/{validationSchema?.maxLength?.value}</span>
      <span className="error">{errors[name]?.message}</span>
      {errors && errors[name]?.type === "required" && (
        <span className="error">{errors[name]?.message}</span>
      )}
      {errors && errors[name]?.type === "minLength" && (
        <span className="error">{errors[name]?.message}</span>
      )}
    </Input>
  );
}
  export default TextArea;
