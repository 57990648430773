export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const NAME_REGEX = /^[a-zA-Z\s]+$/;
export const PHONE_REGEX = /^[0-9]{10}$/;
export const NUMBER_REGEX = /^[0-9]+$/;
export const URL_REGEX = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
export const URL_INVALID_MESSAGE = 'Please enter a valid url';
export const EMAIL_REQUIRED_MESSAGE = 'Email is required';
export const EMAIL_INVALID_MESSAGE = 'Please enter a valid email';
export const EMAIL_MAX_MESSAGE = 'Email cannot be more than 50 characters';
export const EMAIL_MIN_MESSAGE = 'Email cannot be less than 6 characters';
export const PASSWORD_REQUIRED_MESSAGE = 'Password is required';
export const PASSWORD_MIN_MESSAGE = 'Password must be at least 8 characters';
export const PASSWORD_MAX_MESSAGE = 'Password cannot be more than 20 characters';
export const PASSWORD_PATTERN_MESSAGE = 'Password must contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character';
export const NAME_REQUIRED_MESSAGE = 'Name is required';
export const NAME_MAX_MESSAGE = 'Name cannot be more than 50 characters';
export const NAME_MIN_MESSAGE = 'Name cannot be less than 3 characters';
export const NAME_PATTERN_MESSAGE = 'Name can contain only alphabets and spaces';
export const PHONE_REQUIRED_MESSAGE = 'Phone number is required';
export const PHONE_MAX_MESSAGE = 'Phone number cannot be more than 10 digits';
export const PHONE_MIN_MESSAGE = 'Phone number cannot be less than 10 digits';
export const PHONE_PATTERN_MESSAGE = 'Phone number must contain only numbers';
export const NUMBER_REQUIRED_MESSAGE = 'Number is required';
export const NUMBER_MAX_MESSAGE = 'Number cannot be more than 10 digits';
export const NUMBER_MIN_MESSAGE = 'Number cannot be less than 10 digits';
export const NUMBER_PATTERN_MESSAGE = 'Number must contain only numbers';
