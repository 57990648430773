import ToolTip from "components/Tooltip";
import { ImageButtonComponent as ImageButton } from "components/Button";
import React from "react";
import { TitleWrapper } from "./styled";

export default function TitleComponent({
  isLocked,
  icon,
  tooltipContent,
  children,
}) {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const handleButtonClick = () => {
    setShowTooltip(!showTooltip);
  };
  return (
    <TitleWrapper className="d-flex align-items-center position-relative">
      <h4 className={`title ${isLocked ? "lock" : ""}`}>{children}</h4>
      <div className="img-btn-wrapper position-relative">
        <ImageButton
          onClick={() => handleButtonClick()}
          src={icon}
        />
        {(showTooltip && tooltipContent) && (
          <div className="tooltip-wrapper position-aboslute">
            <ToolTip>
              <span className="header">{tooltipContent["header"]}</span>
              <ul>
                {tooltipContent["list"].map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </ToolTip>
          </div>
        )}
      </div>
    </TitleWrapper>
  );
}
