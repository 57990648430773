import React, { useEffect } from "react";
import { Input } from "./styled";
import { CloseIcon2, ImageBrokenIcon, ImageUploadIcon } from "utils/imageConstants";

export default function ImagePickerComponent({
  // name,
  name,
  label,
  readOnly = false,
  placeholder,
  register,
  setValue,
  clearErrors,
  errors,
  disabled,
  defaultValue,
  required,
  onChange, // missing
  onFocus, // missing
  onBlur, // missing
  validationSchema,
  //
  maxSize = 2097152, // 2MB
  maxResolution,
}) {
  const { ref, ...rest } = register(name, {
    required: required && "This field is required",
  });
  const [image, setImage] = React.useState(defaultValue);
  const [fileSize, setFileSize] = React.useState(null);
  const [highlight, setHighlight] = React.useState(false);
  const [fileRejection, setFileRejection] = React.useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      setFileRejection(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [fileRejection]);

  const handleImageChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);

    const file = e?.dataTransfer?.files[0] || e?.target?.files[0];
    if (file) {
      setFileSize(file.size);
      const fileSizeInMB = (file.size / 1024 / 1024).toFixed(2);
      const fileResolution = maxResolution
      const reader = new FileReader();
      if (fileSizeInMB > maxSize / 1024 / 1024) {
        setFileRejection("File size should be less than 2 MB");
        return;
      }

      // if (fileResolution) {
      //   const [width, height] = maxResolution.split("x");
      //   const img = new Image();
      //   img.src = file;
      //   return new Promise((resolve) => {
      //     img.onload = () => {
      //       if (
      //         img.naturalWidth < width ||
      //         img.naturalHeight < height
      //       ) {
      //         setFileRejection(`Image resolution should be greater than ${width}x${height}`);
      //       }
      //       return resolve(true);
      //     };
      //   });
      // }


      reader.onload = () => {
        setImage(reader.result);
        setValue(name, file);
      };
      reader.readAsDataURL(file);
    }
  };

  // drop image
  const handleEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlight(true);
  };

  const handleOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlight(true);
  };

  const handleLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setHighlight(false);
  };

  const handleDeleteClick = () => {
    setImage(null);
    setValue(name, '');
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, []);

  return (
    <Input
      onDragEnter={(e) => handleEnter(e)}
      onDragLeave={(e) => handleLeave(e)}
      onDragOver={(e) => handleOver(e)}
      onDrop={(e) => handleImageChange(e)}
    >
      <label className="label" htmlFor={name}>
        {required && "*"} {label}
      </label>
      <div
        className="image-picker-input"
        onClick={() => document.getElementById(name).click()}
      >
        {/* ADD INPUT BUTTON */}
        {!image && !readOnly && (

          <input
            className="input"
            id={name}
            name={name}
            hidden
            type="file"
            accept=".png, .jpg, .webp, .jpeg"
            {...register(name, {
              ...(validationSchema
                ? validationSchema
                : { required: required || false }),
              validate: (value) => {
                if (value && fileSize > maxSize) {
                  return `File size should be less than ${maxSize / 1024 / 1024
                    } MB`;
                }
                if (value && maxResolution) {
                  const [width, height] = maxResolution.split("x");
                  const img = new Image();
                  img.src = value;
                  return new Promise((resolve) => {
                    img.onload = () => {
                      if (
                        img.naturalWidth < width ||
                        img.naturalHeight < height
                      ) {
                        return resolve(
                          `Image resolution should be greater than ${width}x${height}`
                        );
                      }
                      return resolve(true);
                    };
                  });
                }
                return true;
              },
              onChange: handleImageChange,
            })}
          />)}
        {/* ADD BUTTON STYLING */}
        <div className={"image-picker-input-label"}>
          {!image && (
            <div className={"dropzone"}>
              <div className={`no_file ${highlight ? "highlight" : ""}`}>
                <img
                  loading="lazy"
                  className="input-upload-icon"
                  src={ImageUploadIcon}
                  alt={name}
                />
                <p className="input-text">Drag and Drop, Browse</p>
                <p className="input-text parameters">
                  Max Size: {maxSize / 1024 / 1024}MB
                </p>
                <p className="input-text parameters">Dimensions: 1100*600</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* IMAGE PREVIEW */}
      {image && (
        <div className="dropzone d-flex flex-row align-items-center">
          <img className="preview" src={image} alt="preview"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = ImageBrokenIcon;
              currentTarget.className = 'broken-image';
            }}
          />
          {!readOnly && (
            <div className="delBtn" onClick={handleDeleteClick}>
              <img src={CloseIcon2} alt="" />
            </div>
          )}
        </div>
      )}
      {fileRejection && <span className="error">{fileRejection}</span>}
      {errors && errors[name]?.type === "required" && (
        <span className="error">{errors[name]?.message}</span>
      )}
      {errors && errors[name]?.type === "maxSize" && (
        <span className="error">{errors[name]?.message}</span>
      )}
      {errors && errors[name]?.type === "maxResolution" && (
        <span className="error">{errors[name]?.message}</span>
      )}
    </Input>
  );
}
