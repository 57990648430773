import React from "react";
import { ModalWrapper } from "./styled";
import { CloseIcon } from "utils/imageConstants";
import Button from "components/Button";

export default function ModalComponent({
  icon,
  title,
  desc,
  buttonTitle,
  showModal,
  setShowModal,
  yesNoButton,
  submitData,
}) {
  const handleSubmitData = () => {
    if (submitData) {
      submitData();
    }
    setShowModal(false)
  }
  return (
    <ModalWrapper className="custom-modal w-100">
        <div className="modal-body">
          <div className={"closeBtn"} onClick={() => setShowModal(false)}>
            <img src={CloseIcon} alt="close-btn" />
          </div>
          <img src={icon || ""} alt="" className="modal-icon" />
          <div className="body-content">
            {<h4 className="text-center">{title || ""}</h4>}
            <p>{desc || ""}</p>
          </div>
          {(buttonTitle && !yesNoButton) && (
            <Button className="solid" onClick={() => handleSubmitData()} label={buttonTitle} />
          )}
          {yesNoButton && (
            <div className="btn-group w-100 gap-4">
              <Button className="solid w-100" onClick={() => handleSubmitData()} label={yesNoButton.yes} />
              <Button className="bordered w-100" onClick={() => setShowModal(false)} label={yesNoButton.no}/>{/*  no button */}
            </div>
          )}
      </div>
    </ModalWrapper>
  );
}
