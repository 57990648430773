export const getToken = () => {
  return sessionStorage.getItem("token");
};
export const removeToken = () => {
  sessionStorage.removeItem("token");
};
export const setToken = (val) => {
  sessionStorage.setItem("token", val);
};

export const base64ToFile = (base64Str, fileName) => {
  const arr = base64Str.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  const blob = new Blob([u8arr], { type: mime });

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const file = new File([u8arr], fileName, {
    type: mime,
    lastModified: new Date(),
    lastModifiedDate: new Date().getDate(),
  });
  Object.defineProperty(file, "preview", {
    value: URL.createObjectURL(blob),
    writable: true,
    enumerable: true,
    configurable: true,
  });
  return file;
};

export const fetchImage = async ({inputUrl, name, setFiles}) => {
  const corseFix = 'https://cors-anywhere.herokuapp.com/'
  const response = await fetch((corseFix + inputUrl), {
    headers: {
      'Access-Control-Allow-Origin': '*',
      mode: 'no-cors',
    },
  })
  if (!response.ok) {
    console.error(`failed to fetch image: ${inputUrl}`);
    return;
  }
  const blob = await response.blob();
  const file = new File([blob], name);
  const url = URL.createObjectURL(file);
  setFiles([Object.assign(file, { preview: url })]);
};

function convertImageToDataURL(url, callback) {
    // Create an Image object
    const img = new Image();
    // Set the crossOrigin attribute to handle images from different origins
    img.crossOrigin = 'Anonymous';

    // Define what happens when the image loads
    img.onload = function() {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        // Set the canvas dimensions to match the image dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Get the canvas drawing context
        const ctx = canvas.getContext('2d');
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Convert the canvas content to a Data URL
        const dataURL = canvas.toDataURL('image/png');
        // Call the callback function with the Data URL
        callback(dataURL);
    };

    // Define what happens in case of an error
    img.onerror = function() {
        console.error('An error occurred while loading the image.');
    };

    // Set the image source to the provided URL
    img.src = url;
}

export function unescapeHTML(str) {
  return str.replace(/&amp;/g, "&")
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">")
      .replace(/&quot;/g, '"')
      .replace(/&#039;/g, "'")
      .replace(/&nbsp;/g, " ")
      .replace(/<br>/gi, "\n");
}