import React, { useEffect, useState } from "react";
import { DashboardLeft, DashboardRight, DashboardWrapper } from "./styled";
import BottomNavbar from "components/Navbar/BottomNavbar";
import Input from "components/Input";
import TextArea from "components/TextArea";
import ImagePicker from "components/Image/ImagePicker";
import Modal from "components/Modal";
import Title from "components/Title";
import SpinnerComponent from "components/Spinner";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import {
  FileUploadWarning,
  InfoIcon,
  ThumbsUpWarning,
  Warning,
} from "utils/imageConstants";
import { useDispatch, useSelector } from "react-redux";
import { URL_INVALID_MESSAGE, URL_REGEX } from "utils/validations";
import { fetchCompanyDataThunk } from "./../../redux/thunk/getDataThunk";
import { postDataThunk } from "./../../redux/thunk/postDataThunk";
import { unescapeHTML } from "utils/helper";
import PreviewComponent from "./components/PreviewComponent";

const RecognitionsComponent = ({ recognitionsData }) => {
  const allData = Array.isArray(recognitionsData) ? recognitionsData : [];
  return (
    <>
      {allData.map((data) => (
        <div className="col-sm-4 recognition-card">
          <img className="recognition-img" src={data.img} alt={data.recid} />
          <p className="recognition-title">{data.title}</p>
        </div>
      ))}
    </>
  );
};

function DashboardNested({ data }) {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {...data, 
      employee_quote: {
        quote1: unescapeHTML(data?.employee_quote?.quote1) || "",
        quote2: unescapeHTML(data?.employee_quote?.quote2) || "",
      }
    },
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const selector = useSelector((state) => state.auth);
  const { isLoading } = selector;
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [imageSlider, setImageSlider] = useState([{}]);
  const sliderImagesLimit = 5;

  const [previewData, setPreviewData] = useState(selector.userData);
  const [switchPreview, setSwitchPreview] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 992;
      setSwitchPreview(isMobile);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  useEffect(() => {
    if (imageSlider.length > 0 && imageSlider[imageSlider.length - 1] !== "") {
      setImageSlider([...imageSlider, ""]);
    }
  }, [imageSlider]);

  const companyDetailsTooltip = {
    header: "Note:",
    list: [
      "You can add upto 5 images.",
      "Click on respective file type to upload the image.",
      "At least add one image",
    ],
  };
  const socialLinksTooltip = {
    header: "Note:",
    list: [
      "Add your social media links to connect more user.",
      "If the fields are empty then that field will not be display on the page.",
    ],
  };

  const onPreview = (formData) => {
    let reqmodel = formData;
    if (reqmodel.company_logo && typeof reqmodel.company_logo !== "string") {
      reqmodel.company_logo = URL.createObjectURL(reqmodel.company_logo);
    }
    for (let i = 0; i < sliderImagesLimit; i++) {
      const previewUrl = typeof formData.slider[i] === "string" ? formData.slider[i] : formData.slider[i]?.name ? URL.createObjectURL(formData.slider[i]) : '';
      reqmodel.slider[i] = previewUrl;
    }
    setPreviewData(reqmodel);
  };

  const onSubmit = async (formData) => {
    // form submit data
    try {
      delete formData.badge_logo;
      delete formData.recognitions;
      // delete formData.company_name;
      delete formData.company_size;
      delete formData.industry;
      delete formData.hq_industry;
      delete formData.username;
      delete formData.city;
      delete formData.company_id;
      delete formData.login_id;
      delete formData.gptw_company_id;
      delete formData.slug;
      delete formData.why_great;

      for (let i = 0; i < sliderImagesLimit; i++) {
        const key = `slider${i}`;
        const value = typeof formData.slider[i] === "string" ? formData.slider[i].split('/').pop() : formData.slider[i]?.name ? formData.slider[i] : '';
        formData[key] = value;
      }
      delete formData.slider;
      
      const handlePostDataThunk = async () => {
        try {
          const { payload } = await dispatch(postDataThunk(formData));
          if (payload.success) {
            setModalData({
              icon: ThumbsUpWarning,
              title: "Submitted Successfully!",
              desc: payload.message,
              buttonTitle: "OKAY!",
              submitData: () => {
                return window.location.reload();
              }
            });
            setShowModal(true);
          } else {
            setShowModal(true);
            setModalData({
              icon: Warning,
              title: "An Error Occurred!",
              desc: payload,
              buttonTitle: "TRY AGAIN",
            });
          }
        } catch (error) {
          console.log(error, "error");
          setModalData({
            icon: Warning,
            title: "An Error Occurred!",
            desc: error.message,
            buttonTitle: "TRY AGAIN",
          });
        }
        setShowModal(true);
      };
      setModalData({
        icon: FileUploadWarning,
        title: "Are you sure you want to submit the form?",
        yesNoButton: { yes: "YES", no: "NO" },
        submitData: handlePostDataThunk,
      });
      setShowModal(true);
    } catch (error) {
      setShowModal(true);
      setModalData({
        icon: Warning,
        title: "An Error Occurred!",
        desc: "We’re not able to submit your form. Please try again later.",
        buttonTitle: "TRY AGAIN",
      });
    } finally {
      // reset();
    }
  };

  const onError = (errors, e) => {
    console.log(errors, 'errors')
    try {
      setShowModal(true);
      setModalData({
        icon: Warning,
        title: "An Error Occurred!",
        desc: errors[Object.keys(errors)[0]].message,
        buttonTitle: "TRY AGAIN",
      });
    } catch (error) {
      console.error(error, "error");
    }
  }
  return (
    <DashboardWrapper>
      <div className="row">
        {/* --------------- EDITOR SECTION --------------- */}
        <DashboardLeft className="col-lg-6">
          <form
            className="container"
            // onSubmit={handleSubmit(onSubmit)}
            // encType="multipart/form-data"
          >
            {/* Company Details */}
            <div className="form-container">
              <Title icon={InfoIcon} tooltipContent={companyDetailsTooltip}>
                Company Details
              </Title>
              {/* image picker */}
              <div className="row">
                <div className="col-sm-6">
                  <ImagePicker
                    name="company_logo"
                    label="Company Logo"
                    placeholder="Upload Image"
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    defaultValue={data.company_logo}
                    validationSchema={{
                      required: "Please upload a company logo",
                    }}
                    errors={errors}
                    disabled={false}
                    required={true}
                  />
                </div>
                <div className="col-sm-6">
                  <ImagePicker
                    name="badge_logo"
                    label="Badge Logo"
                    placeholder="Upload Image"
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    defaultValue={data.badge_logo}
                    validationSchema={{
                      required: false,
                    }}
                    errors={errors}
                    readOnly
                    required={true}
                  />
                </div>
              </div>
              <div className="row form-grid">
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="company_name"
                    label="Company name"
                    placeholder={"Enter Company Name"}
                    errors={errors}
                    // disabled
                    register={register}
                    validationSchema={{
                      required: "Company name is required",
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                    // required
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="number"
                    name="company_size"
                    label="Company Size"
                    placeholder={"Enter Company Name"}
                    errors={errors}
                    disabled
                    register={register}
                    validationSchema={{
                      required: "Company size is required",
                    }}
                    // required
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="industry"
                    label="Industry Name"
                    placeholder={"Enter Industry Name"}
                    errors={errors}
                    disabled
                    register={register}
                    validationSchema={{
                      required: "Industry name is required",
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                    // required
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="city"
                    label="HQ Industry"
                    placeholder={"Enter HQ Industry"}
                    errors={errors}
                    disabled
                    register={register}
                    validationSchema={{
                      required: "HQ Industry is required",
                    }}
                    // required
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="text"
                    name="username"
                    label="Login email"
                    placeholder={"Enter Login Email"}
                    errors={errors}
                    disabled
                    register={register}
                    validationSchema={{
                      required: "Login email is required",
                    }}
                    // required
                  />
                </div>
              </div>
            </div>
            {/* SOCIAL LINKS */}
            <div className="form-container">
              <Title icon={InfoIcon} tooltipContent={socialLinksTooltip}>
                Social Links
              </Title>
              <div className="row social_links">
                <div className="col-md-6">
                  <Input
                    type="facebook"
                    name="social_links[fb]"
                    placeholder={"Enter Facebook Link"}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: false,
                      pattern: {
                        value: URL_REGEX,
                        message: URL_INVALID_MESSAGE,
                      },
                    }}
                    // required
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="twitter"
                    name="social_links[twitter]"
                    placeholder={"Enter Twitter Link"}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: false,
                      pattern: {
                        value: URL_REGEX,
                        message: URL_INVALID_MESSAGE,
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="linkedin"
                    name="social_links[linkedin]"
                    placeholder={"Enter linkedIn Link"}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: false,
                      pattern: {
                        value: URL_REGEX,
                        message: URL_INVALID_MESSAGE,
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="instagram"
                    name="social_links[instagram]"
                    placeholder={"Enter Instagram Link"}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: false,
                      pattern: {
                        value: URL_REGEX,
                        message: URL_INVALID_MESSAGE,
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="youtube"
                    name="social_links[youtube]"
                    placeholder={"Enter Youtube Link"}
                    errors={errors}
                    register={register}
                    // validationSchema={{
                    //   required: "Company name is required",
                    //   minLength: {
                    //     value: 3,
                    //     message: "Please enter a minimum of 3 characters",
                    //   },
                    // }}
                    // required
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="custom"
                    name="social_links[career]"
                    placeholder={"Enter Career Link"}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: false,
                      pattern: {
                        value: URL_REGEX,
                        message: URL_INVALID_MESSAGE,
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <Input
                    type="custom"
                    name="social_links[website]"
                    placeholder={"Enter Your Link"}
                    errors={errors}
                    register={register}
                    validationSchema={{
                      required: false,
                      pattern: {
                        value: URL_REGEX,
                        message: URL_INVALID_MESSAGE,
                      },
                      minLength: {
                        value: 3,
                        message: "Please enter a minimum of 3 characters",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="divider"></div>
            {/* Recognitions */}
            <div className="form-container">
              <Title icon={InfoIcon} isLocked>
                Recognitions
              </Title>

              {/* <h4 className="title lock">Recognitions</h4> */}
              <div className="row recognitions-wrapper">
                <RecognitionsComponent recognitionsData={data.recognitions} />
              </div>
            </div>
            <div className="divider"></div>
            {/* Add Slider Images */}
            <div className="form-container">
              <Title icon={InfoIcon}>Add Slider Images</Title>
              {/* image picker */}
              <div className="row image-grid">
                <div className="col-sm-4">
                  <ImagePicker
                    name="slider[0]"
                    placeholder="Upload Image"
                    register={register}
                    setValue={setValue}
                    defaultValue={data.slider[0]}
                    clearErrors={clearErrors}
                    validationSchema={
                      {
                        // required: true,
                      }
                    }
                    errors={errors}
                    disabled={false}
                    // required={true}
                  />
                </div>
                <div className="col-sm-4">
                  <ImagePicker
                    name="slider[1]"
                    placeholder="Upload Image"
                    register={register}
                    setValue={setValue}
                    defaultValue={data.slider[1]}
                    clearErrors={clearErrors}
                    validationSchema={
                      {
                        // required: true,
                      }
                    }
                    errors={errors}
                    disabled={false}
                    // required={true}
                  />
                </div>
                <div className="col-sm-4">
                  <ImagePicker
                    name="slider[2]"
                    placeholder="Upload Image"
                    register={register}
                    setValue={setValue}
                    defaultValue={data.slider[2]}
                    clearErrors={clearErrors}
                    validationSchema={
                      {
                        // required: true,
                      }
                    }
                    errors={errors}
                    disabled={false}
                    // required={true}
                  />
                </div>
                <div className="col-sm-4">
                  <ImagePicker
                    name="slider[3]"
                    placeholder="Upload Image"
                    register={register}
                    setValue={setValue}
                    defaultValue={data.slider[3]}
                    clearErrors={clearErrors}
                    validationSchema={
                      {
                        // required: true,
                      }
                    }
                    errors={errors}
                    disabled={false}
                    // required={true}
                  />
                </div>
                <div className="col-sm-4">
                  <ImagePicker
                    name="slider[4]"
                    placeholder="Upload Image"
                    register={register}
                    setValue={setValue}
                    defaultValue={data.slider[4]}
                    clearErrors={clearErrors}
                    errors={errors}
                    disabled={false}
                    // required={true}
                  />
                </div>
              </div>
            </div>
            <div className="divider"></div>
            {/* Employee Quote */}
            <div className="form-container employee-quote">
              <Title icon={InfoIcon}>Employee Quote</Title>
              <div className="card-container">
                <TextArea
                  type="text"
                  name="employee_quote[quote1]"
                  label="Employee Quote 1"
                  // readOnly
                  defaultValue={data.employee_quote.quote1}
                  placeholder={"Type the employee quote...."}
                  errors={errors}
                  validationSchema={{
                    maxLength: {
                      value: 500,
                      message: "Maximum of 500 characters allowed",
                    },
                  }}
                  register={register}
                />
              </div>
              <div className="card-container">
                <TextArea
                  type="text"
                  name="employee_quote[quote2]"
                  label="Employee Quote 2"
                  // readOnly
                  defaultValue={data.employee_quote.quote2}
                  placeholder={"Type the employee quote...."}
                  errors={errors}
                  validationSchema={{
                    maxLength: {
                      value: 500,
                      message: "Maximum of 500 characters allowed",
                    },
                  }}
                  register={register}
                />
              </div>
            </div>
          </form>
        </DashboardLeft>
        {/* --------------- PREVIEW SECTION -------------- */}
        {!switchPreview && <DashboardRight className="col-lg-6">
          <PreviewComponent data={previewData}/>
        </DashboardRight>}
      </div>
      <BottomNavbar>
        {!switchPreview && <Button
          className="solid-blue"
          isLoading={isLoading}
          // disabled
          onClick={handleSubmit(onPreview)}
          label={"PREVIEW"}
        />}
        <Button
          className="solid"
          isLoading={isLoading}
          onClick={handleSubmit(onSubmit, onError)}
          label={"SAVE"}
        />
      </BottomNavbar>
      {showModal && (
        <Modal
          setShowModal={setShowModal}
          icon={modalData.icon}
          title={modalData.title}
          desc={modalData.desc}
          yesNoButton={modalData.yesNoButton}
          buttonTitle={modalData.buttonTitle}
          submitData={modalData.submitData}
        />
      )}
    </DashboardWrapper>
  );
}
export default function Dashboard() {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      let reqmodel = await dispatch(fetchCompanyDataThunk());

      if (reqmodel.pending) {
        console.log("Promise pending");
      } else {
        setUserData(reqmodel.payload);
      }
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <div className="position-absolute top-50 start-50 translate-middle">
          <SpinnerComponent />
        </div>
      ) : (
        userData && <DashboardNested data={userData} />
      )}
    </>
  );
}
