import styled from "styled-components";

export const TooltipWrapper = styled.div`
  position: absolute;
  z-index: 10000;
  border-radius: 0.125rem;
  border: 0.5px solid var(--bs-black);
  background: #fff;
  box-shadow: 0rem 0.875rem 1.25rem 0rem rgba(0, 0, 0, 0.05);
  /* top: 1.3rem; */
  /* right: 0.4rem; */
  background-color: var(--bs-white);
  /* padding: 0.5rem; */

  .tooltip-content {
    font-family: Gilroy-Semibold;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    min-width: 9.375rem;
    padding: 0.6875rem 0.5625rem 0.75rem 0.5rem;

    .header {
      padding-bottom: 0.5rem;
    }

    ul {
      padding-block: 0.5rem 0;
      margin-bottom: 0; 
      padding-left: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }
  }

  .tooltip-down {
    position: absolute;
    bottom: -0.65rem;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }
`;
