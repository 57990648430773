import React from "react";
import { FormWrapper } from "./styled";
import { Button } from "components/Button/styled";
import { InfoIcon } from "utils/imageConstants";

export default function FormComponent({
  children,
  handleSubmit,
  submitLabel,
  footerContent,
  width,
}) {
  return (
    <FormWrapper
      width={width}
      method="post"
      className="d-flex flex-column"
      onSubmit={handleSubmit}
    >
      {children}
      {submitLabel && (
        <Button className="solid" type="submit" value={submitLabel}>
          {submitLabel}
        </Button>
      )}
      {footerContent && (
        <div className="footer">
          <img src={InfoIcon} alt="info-icon" />
          <p>{footerContent}</p>
        </div>
      )}
    </FormWrapper>
  );
}
