import { InfoIcon, LockIcon, LockIcon2 } from "utils/imageConstants";
import styled from "styled-components";

export const DashboardWrapper = styled.div`
  background-color: var(--bs-white);
  /* overflow: hidden; */
  position: relative;
`;

export const DashboardLeft = styled.div`
  height: 90vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0rem;
  }
  position: sticky;
  top: 0;
  .title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    &.lock::before {
      content: "";
      background-image: url(${LockIcon2});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      display: inline-flex;
      justify-content: center;
      height: 1.18619rem;
      aspect-ratio: 1;
      svg {
        fill: var(--bs-black);
      }
    }
  }

  .form-container {
    gap: 2rem;
    display: flex;
    flex-direction: column;
    margin: 3rem 0;
  }

  .recognitions-wrapper {
    row-gap: 1rem;
    max-height: 30rem;
    overflow: none;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .recognition-card {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    /* gap: 0.5rem; */
    .recognition-img {
      padding: 1rem;
      object-fit: contain;
      max-height: 7.40538rem;
    }
    .recognition-title {
      max-width: 80%;
      color: #1f1f1f;
      text-align: center;
      font-family: Gilroy-SemiBold;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.01225rem;
    }
  }

  .form-grid {
    row-gap: 1.5rem;
  }

  .image-grid {
    row-gap: 1rem;
  }

  .social_links {
    row-gap: 1rem;
  }
  /* background-color: lightcoral;     */
`;

export const DashboardRight = styled.div`
  background-color: var(--bs-background);
  overflow-y: scroll;
  position: sticky;
  top: 0;
`;
