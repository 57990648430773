import { createGlobalStyle } from "styled-components";
import {
  GilroyBlack,
  GilroyBold,
  GilroyExtraBold,
  GilroyLight,
  GilroyRegular,
  GilroySemiBold,
} from "./fontConstants";

export const GlobalVariables = createGlobalStyle`

    :root {
    --bs-red: #E60000;
    --bs-red-dark: #D20000;
    --bs-red-darker: #BE0000;
    --bs-blue: #002E72;
    --bs-blue-light: #174BD6; /* a new blue color */
    --bs-blue-dark: #001D4A;
    --bs-blue-darker: #00112F;
    --bs-yellow: #C99732;
    --bs-dark: #12131C;
    --bs-dark2: #272833;  /* a new dark color */
    --bs-dark-gray: #292B30;
    --bs-gray: #5C6066;
    --bs-gray-light: #B4B4B4;
    --bs-light: #E5E5E5;
    --bs-light-gray: #F3F3F6;
    --bs-light-gray2: #E7E7ED;  /* another light gray color */
    --bs-light-gray3: #EFEFEF; /* another light gray color */

    --bs-black: #000;
    --bs-white: #fff;

    --bs-background: #FAFAFA;
    }

    @media only screen and (min-width: 720px) {
        html {
            font-size: 8px;
        }
    }

    @media only screen and (min-width: 960px) {
        html {
            font-size: 10px;
        }
    }

    @media only screen and (min-width: 1240px) {
        html {
            font-size: 12px;
        }
    }

    @media only screen and (min-width: 1440px) {
        html {
            font-size: 16px;
        }   
    }

    .theme-text {
        color: var(--bs-dark);
    }
    .theme-text-light {
        color: var(--bs-gray-light);
    }


   /* Custom scroll style */
   ::-webkit-scrollbar {
     position: absolute;
     height: 0.5rem;
     width: 0.5rem;
   }
   ::-webkit-scrollbar-thumb {
     background: var(--bs-light-gray2);
     border-radius: 1rem;
   }
   ::-webkit-scrollbar-thumb:hover {
     background: var(--bs-gray);
   }

   /* Adjust height of scrollbar if horizontal */
   ::-webkit-scrollbar-track-piece {
     height: 0.5rem;
   }

    body {
        box-sizing: border-box;
        background-color: var(--bs-background);
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        color: var(--theme-black);
        -moz-osx-font-smoothing: grayscale;
        overflow-x: hidden;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -webkit-text-size-adjust: auto;
        -moz-text-size-adjust: auto;
        -ms-text-size-adjust: auto;
        text-size-adjust: auto;
        font-family: 'Gilroy-Regular', sans-serif;
        font-size: 16px;

        .App {
            min-height: 100vh;
            height: 100%;
        }

        img {
            user-select: none;
        }

        @media (min-width: 768px) and (max-width: 1023px) {
            font-size: 18px;
        }

        @media (min-width: 1024px) and (max-width: 1279px) {
            font-size: 20px;
        }

        @media (min-width: 1280px) and (max-width: 1439px) {
            font-size: 22px;
        }

        @media (min-width: 1440px) {
            font-size: 24px;
        }
    }

    * {
        padding: 0;
        margin: 0;
    }


    html {
        scroll-behavior: smooth;
        font-size: 16px;
    }


    h1, h3, h4, h5, h6 {
        color: var(--bs-black);
        font-family: 'Gilroy-SemiBold';
        margin:0;
        padding:0;
    }

    .header-icon {
        width: 1.125rem;
        aspect-ratio: 1;
        color: var(--bs-black);
    }

    h1, .h1 {
        font-size: 4.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 5.625rem; /* 125% */
        letter-spacing: -0.063rem;
    }

    h2, .h2 {
        font-size: 4rem;
        font-style: normal;
        font-weight: 600;
        line-height: 5rem; /* 125% */
        letter-spacing: -0.056rem;
    }


    h3, .h3 {
        font-size: 3rem;
        font-style: normal;
        font-weight: 600;
        line-height: 3.75rem; /* 125% */
        letter-spacing: -0.042rem;
    }

    h4, .h4 {
        font-size: 2.25rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.8125rem; /* 125% */
        letter-spacing: -0.0315rem;
    }

    h5, .h5 {
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.1875rem; /* 125% */
        letter-spacing: -0.0245rem;
    }

    h6, .h6 {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.875rem; /* 125% */
        letter-spacing: -0.021rem;
    }

    p {
        color: var(--bs-black);
        font-family: 'Gilroy-Regular';
        font-size: 1rem;
        font-style: normal;
        line-height: normal;
        margin: 0;

    }
    p.bold {
        font-family: 'Gilroy-SemiBold';
    }

    .spinner-border {
        --bs-spinner-width: 1.5rem;
        --bs-spinner-height: 1.5rem;
        --bs-spinner-border-width: 0.25em;
        --bs-spinner-animation-speed: 1s;
        --bs-spinner-animation-name: spinner-border;
        border: var(--bs-red) solid;
        border-right-color: var(--bs-light-gray);
    }

    // spacings
    .mt-1 {
        margin-top: 1.25rem;
    }

    .custom-header {
        background-color:'yellow';
    }

    .text-color-white {
        color: var(--bs-white);
    }

    /* For all modern browsers */
    ::selection {
        color: var(--bs-white);
        background-color: var(--bs-red);
    }

    /* For Mozilla Firefox */
    ::-moz-selection {
        color: var(--bs-white);
        background-color: var(--bs-red);
    }

    // TODO : boostrap container
    .container {
        max-width: 100%;
        padding: 0 2rem;
    }

    // DIVIDER
    .divider {
        border-top: 1px solid var(--bs-light-gray3);
        width: 100%;
    }
    
    .card-container {
        border: 1px solid #F0F0F0;
        padding: 1.7rem 1.8rem;
    }


    // responsive sizes
    @media only screen and (max-width: 1080px) {
        html {
            font-size: 14px;
        }
    }
`;

export const FontVariable = createGlobalStyle`
    // Gilroy
    @font-face {
        font-family: 'Gilroy-Light';
        src: url(${GilroyLight}) format('truetype');
        font-weight: 300;
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Gilroy-Regular';
        src: url(${GilroyRegular}) format('truetype');
        font-weight: 400;
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Gilroy-SemiBold';
        src: url(${GilroySemiBold}) format('truetype');
        font-weight: 600;
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Gilroy-Bold';
        src: url(${GilroyBold}) format('truetype');
        font-weight: 700;
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Gilroy-ExtraBold';
        src: url(${GilroyExtraBold}) format('truetype');
        font-weight: 800;
        font-style: normal;
        font-display: auto;
    }

    @font-face {
        font-family: 'Gilroy-Black';
        src: url(${GilroyBlack}) format('truetype');
        font-weight: 900;
        font-style: normal;
        font-display: auto;
    }
`;
