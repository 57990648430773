import styled from "styled-components";

export const FormWrapper = styled.form`
  background-color: var(--bs-white);
  padding: 2rem;
  box-shadow: 0px 33px 54px 0px rgba(99, 99, 99, 0.05);
  max-width: ${(props) => props.width};

  .error {
    color: var(--bs-red);
    font-size: 0.85rem;
  }

  button[type="submit"] {
    margin-top: 6rem;
    font-family: Gilroy-SemiBold;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5625rem; /* 125% */
    text-transform: uppercase;
  }

  .input-container {
    gap: 2.25rem;
  }

  .footer {
    margin-top: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
    p {
      font-size: 0.75rem;
      color: var(--bs-gray);
    }
  }
`;
